import React from 'react';
import { FaInstagram } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { IoLogoTwitter } from "react-icons/io";
const Footer = () => {
  return (
    <footer className="bg-gray-200  py-8 font-white pt-20">
      <div className="container mx-auto px-4">
        <div className="flex justify-between">
            <div className='flex flex-col'>
                <img src='https://firebasestorage.googleapis.com/v0/b/cabservicebooking-5f307.appspot.com/o/t-taxi-long%20logo.jpg?alt=media&token=de643d09-185d-4c73-a6cc-6dbeb703f3f3' className='h-20'/>
                <div className='flex justify-between pt-5'>
                    <a href=''><FaInstagram className='size-[30px]'/></a>
                    <a href=''><IoLogoYoutube className='size-[30px]'/></a>
                    <a href=''><IoLogoTwitter className='size-[30px]'/></a>
                </div>
            </div>
          {/* <div className="w-1/4 p-4">
            <h3 className="text-lg font-semibold mb-4">About tTaxi</h3>
            <ul className="text-sm flex flex-col space-y-4">
               
              <li >About Us</li>
              <li>Blog</li>
              <li>Careers</li>
              <li>Contact Us</li>
              
            </ul>
          </div> */}
          {/* <div className="w-1/4 p-4">
            <h3 className="text-lg font-semibold mb-4">Services</h3>
            <ul className="text-sm pb-4 flex flex-col space-y-4 ">
              <li>Ttaxi Ride</li>
              <li>Ttaxi Rentals</li>
              <li>Ttaxi Outstation</li>
              <li>Ttaxi Corporate</li>
            </ul>
          </div> */}
          <div className="w-1/4 p-4">
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="text-sm flex flex-col space-y-4">
              <a href='terms.html'><li>Terms and Conditions</li></a>
              <a href='privacy.html'><li>Privacy Policy</li></a>
            </ul>
          </div>
          
        </div>
        <div className="mt-8 text-center">
          <p className="text-sm">&copy; {new Date().getFullYear()} Ttaxi Cabs. Contact Support via email at support@ttaxis.in or generate ticket in App.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

